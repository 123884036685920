
var snowplow = {}

const init = () => {
    
    const tracker_script = '//d1fc8wv8zag5ca.cloudfront.net/2.8.2/sp.js';
    const collector_url = 'hideaway.sinter-collect.com';
    const app_id = 'hideaway';
    const cookie_domain = '.hideawayreport.com';
    const page_ping_interval = 10; // seconds
    const track_pseudo_clicks = true;
    const track_link_content = true;
    
    (function (p, l, o, w, i, n, g) {
        if (!p[i]) {
            p.GlobalSnowplowNamespace = p.GlobalSnowplowNamespace || [];
            p.GlobalSnowplowNamespace.push(i);
            p[i] = function () {
                (p[i].q = p[i].q || []).push(arguments)
            };
            p[i].q = p[i].q || [];
            n = l.createElement(o);
            g = l.getElementsByTagName(o)[0];
            n.async = 1;
            n.src = w;
            g.parentNode.insertBefore(n, g)
        }
    }(window, document, 'script', tracker_script, 'snowplow'));
    window.snowplow('newTracker', 'cf', collector_url, {
        appId: app_id,
        cookieDomain: cookie_domain,
        contexts: {
            webPage: true
        }
    });
    window.snowplow('enableActivityTracking', page_ping_interval, page_ping_interval);
    window.snowplow('enableLinkClickTracking', null, track_pseudo_clicks, track_link_content);

    // Aggressively whitelist the fields we care about in form tracking
    var form_config = {
        fields: {
            filter: function (elt) {
                return elt.name == "email";
            }
        }
    }

    window.snowplow('enableFormTracking', form_config);
    snowplow = window.snowplow;
}

export {
    init,
    snowplow
}
